<template><div><h1 id="macos-firmware" tabindex="-1"><a class="header-anchor" href="#macos-firmware"><span>macOS Firmware</span></a></h1>
<p>It is recommended to flash coreboot with the Intel Management Engine interface enabled to prevent bugs after waking from sleep.<br>
Without the ME interface enabled, the following bugs can occur after waking from sleep:</p>
<ul>
<li>Blank Electron / Chromium apps</li>
<li>Buggy video playback in web browsers like Firefox or Safari</li>
<li>Display/Wallpaper settings in SysPref/SysSettings</li>
<li>Logging out freezes your system</li>
</ul>
<h2 id="custom-firmware" tabindex="-1"><a class="header-anchor" href="#custom-firmware"><span>Custom Firmware</span></a></h2>
<h3 id="prebuilt" tabindex="-1"><a class="header-anchor" href="#prebuilt"><span>Prebuilt</span></a></h3>
<p>Prebuilts can be found at <a href="https://ethanthesleepy.one/macos/" target="_blank" rel="noopener noreferrer">ethanthesleepy.one</a></p>
<h3 id="building-manually" tabindex="-1"><a class="header-anchor" href="#building-manually"><span>Building Manually</span></a></h3>
<ol>
<li>
<p>Follow the steps <RouteLink to="/docs/firmware/compiling-coreboot.html">here</RouteLink> to download the toolchain and build the firmware</p>
<ul>
<li>Continue these steps once <code v-pre>build-uefi.sh &lt;boardname&gt;</code> has been ran</li>
</ul>
</li>
<li>
<p>Run <code v-pre>make menuconfig</code>. You will be greeted by a menu which looks like the following:</p>
<p><img src="/macos/menuconfig.png" alt="image"></p>
</li>
<li>
<p>Select <code v-pre>Chipset</code></p>
</li>
<li>
<p>Select <code v-pre>Disable HECI1 at the end of boot</code> then press <code v-pre>N</code> to disable. The asterik should be gone:</p>
<p><img src="/macos/heci1.png" alt="image"></p>
</li>
<li>
<p>Use the right arrow keys to select <code v-pre>Save</code> at the bottom, then press <code v-pre>Ok</code></p>
</li>
<li>
<p>Use the right/left arrow keys to select <code v-pre>Exit</code> until back at the prompt.</p>
</li>
<li>
<p>Run <code v-pre>make -j$(nproc)</code> to build the firmware.</p>
</li>
<li>
<p>The output will be found at <code v-pre>./build/coreboot.rom</code></p>
</li>
</ol>
<h2 id="flashing-firmware" tabindex="-1"><a class="header-anchor" href="#flashing-firmware"><span>Flashing Firmware</span></a></h2>
<p>Follow the steps <RouteLink to="/docs/firmware/manually-flashing.html">on this page</RouteLink> to flash the new firmware.</p>
</div></template>


